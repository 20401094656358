import { FieldTypeEnum } from '@/core/enums/field-types';

export const projetosEnquadradosFields = [
  {
    key: "empresaId",
    name: "Cliente",
    type: FieldTypeEnum.AUTOCOMPLETE,
    labelBold: true,
    rel: { to: "clientes", key: "id", name: "fantasia" },
    nao_comenta: true,
  },
  {
    key: "id_projeto",
    name: `Código do Projeto`,
    type: FieldTypeEnum.TEXT,
    labelBold: true,
    rules: [{ rule: "required" }],
    nao_comenta: true,
  },
  {
    key: "titulo",
    name: `Nome do Projeto`,
    type: FieldTypeEnum.TEXT,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "min", params: { size: 3 } }, { rule: "max", params: { size: 250 } }],
    counter: 250,
    maxLength: 250,
    nao_comenta: true,
  },
  {
    key: "data_inicio",
    name: "Data de Início",
    type: FieldTypeEnum.DATE,
    labelBold: true,
    rules: [{ rule: "required" }],
    nao_comenta: true,
  },
  {
    key: "data_fim",
    name: "Data/Previsão de Término",
    type: FieldTypeEnum.DATE,
    labelBold: true,
    rules: [{ rule: "required" }],
    nao_comenta: true,
  },
  {
    key: "responsavelTxt",
    name: "Nome do responsável",
    type: FieldTypeEnum.TEXT,
    rules: [{ rule: "required" }, { rule: "min", params: { size: 3 }}],
    nao_comenta: true,
  },
  {
    key: "responsavelId_cpf",
    name: "CPF",
    type: FieldTypeEnum.AUTOCOMPLETE,
    nowrap: true,
    rel: { to: "colaboradores", key: "id", name: "cpf" },
    nao_comenta: true,
  },
  {
    key: "responsavelId_email",
    name: "E-mail",
    type: FieldTypeEnum.AUTOCOMPLETE,
    rel: { to: "colaboradores", key: "id", name: "email" },
    nao_comenta: true,
  },
  {
    key: "responsavelId_telefone",
    name: "Telefone",
    type: FieldTypeEnum.AUTOCOMPLETE,
    rel: { to: "colaboradores", key: "id", name: "telefone" },
    nao_comenta: true,
  },
  {
    key: "trabalho",
    name: "Benefício",
    type: FieldTypeEnum.SELECT,
    rules: [{ rule: "required" }],
    rel: { to: "trabalho", key: "id", name: "nome" },
  },
  {
    key: "status",
    name: "Status",
    type: FieldTypeEnum.SELECT,
    rel: { to: "status", key: "id", name: "label" },
    colSize: 6,
  },
  /* TIPIFICAÇÃO */
  {
    key: "tipo_projetoId",
    name: "Tipo (Escolha uma das opções)",
    type: FieldTypeEnum.RADIO,
    outline: true,
    labelBold: true,
    rules: [{ rule: "required" }],
    rel: { to: "tipoProjeto", key: "id", name: "descricao" },
  },
  {
    key: "troca_ppb",
    name: `Projeto para cumprir troca de PPB por P,D&I? (Escolha uma das opções)`,
    type: FieldTypeEnum.RADIO,
    outline: true,
    labelBold: true,
    rules: [{ rule: "required" }],
    rel: {
      key: "id",
      name: "descricao",
      to: [
        { id: "Sim", descricao: "Sim" },
        { id: "Nao", descricao: "Não" },
      ],
    },
  },
  {
    key: "alcance",
    name: "Alcance (pode ser marcado mais de uma opção)",
    type: FieldTypeEnum.CHECKBOXES,
    outline: true,
    labelBold: true,
    rules: [{ rule: "required" }],
    rel: { to: "alcance", key: "id", name: "descricao" },
  },
  {
    key: "inovacao",
    name: "Grau de Inovação (pode ser marcado mais de uma opção)",
    type: FieldTypeEnum.CHECKBOXES,
    outline: true,
    labelBold: true,
    itemsPerLine: 3,
    rules: [{ rule: "required" }],
    rel: { to: "inovacao", key: "id", name: "descricao" },
  },
  {
    key: "area_aplicacaoId",
    name: "Área de Aplicação (escolha uma das opções)",
    type: FieldTypeEnum.RADIO,
    outline: true,
    labelBold: true,
    itemsPerLine: 1,
    rules: [{ rule: "required" }],
    rel: { to: "areaAplicacao", key: "id", name: "descricao" },
  },
  {
    key: "qtde_publicacoes",
    name: "Quantidade de Publicações",
    type: FieldTypeEnum.NUMBER,
    rules: [{ rule: "required" }],
    labelBold: true,
  },
  {
    key: "decreto",
    name: "Enquadramento no Artigo 24 do Decreto 5906/2006 (pode ser marcado mais de uma opção)",
    type: FieldTypeEnum.CHECKBOXES,
    outline: true,
    labelBold: true,
    itemsPerLine: 1,
    rules: [{ rule: "required" }],
    rel: { to: "decreto", key: "id", name: "descricao" },
  },
  /* DETALHAMENTO */
  {
    key: "descricao",
    name: `Descrição do Projeto`,
    type: FieldTypeEnum.TEXTAREA,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
    counter: 4000,
    maxLength: 4000,
  },
  {
    key: "tipo_pesquisaId",
    name: "PB, PA ou DE (Escolha uma das opções)",
    type: FieldTypeEnum.RADIO,
    outline: true,
    labelBold: true,
    rules: [{ rule: "required" }],
    rel: { to: "tipoPesquisa", key: "id", name: "descricao" },
  },
  {
    key: "area_projeto",
    name: "Área do Projeto",
    placeholder: "Digite a área ou selecione uma das opções pré-cadastradas",
    type: FieldTypeEnum.COMBOBOX,
    labelBold: true,
    rules: [{ rule: "required" }],
    rel: { to: "areaProjeto" },
  },
  {
    key: "area_projeto_outro",
    name: `Área do Projeto - Outros`,
    type: FieldTypeEnum.TEXT,
    nao_comenta: true,
  },
  {
    key: "keywords",
    name: "Palavras-chave",
    ajuda:
      `Informe palavras chaves relacionadas ao Projeto de P,D&I separadas por ponto e vírgula (;)`,
    type: FieldTypeEnum.TEXT_CHIP,
    labelBold: true,
    placeholder: "Separe termos por ; ou Enter",
    rules: [
      { rule: "required" },
      (value) => {
        if (!value) {
          return true;
        }

        const array = Array.isArray(value) ? value : [value];
        const txt = array.join('; ');
        return txt.length < 3 ? 'Mínimo de 3 caracteres.' : txt.length > 250 ? 'Máximo de 250 caracteres.' : true;
      },
    ],
    counter: 250,
    maxLength: 250,
  },
  {
    key: "naturezaId",
    name: "Natureza (Escolha uma das opções)",
    type: FieldTypeEnum.RADIO,
    outline: true,
    labelBold: true,
    rules: [{ rule: "required" }],
    rel: { to: "natureza", key: "id", name: "descricao" },
  },
  {
    key: "elemento_tecnologico",
    name: "Elemento tecnologicamente novo ou inovador",
    ajuda:
      "O elemento tecnologicamente novo deve representar um progresso científico ou tecnológico. Por progresso científico ou tecnológico compreende-se a aquisição de novos conhecimentos, visando desenvolver/aprimorar produtos, processos e sistemas, o que é a pesquisa aplicada; assim como a comprovação/demonstração de viabilidade técnica ou funcional para produtos, processos, sistemas e serviços ou evidente aperfeiçoamento daqueles já produzidos ou estabelecidos, o que é o desenvolvimento experimental.",
    type: FieldTypeEnum.TEXTAREA,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
    counter: 4000,
    maxLength: 4000,
  },
  {
    key: "desafio_tecnologico",
    name: "Barreiras ou desafios tecnológicos superáveis",
    ajuda:
      "Consiste em um determinado problema, dificuldade, limitação ou restrição de ordem técnica imposto ao desenvolvimento, compreensão e implementação das novas tecnologias, ou novos conhecimentos. As atividades realizadas para superar devem ser de P&D, que sempre apresentará um resultado, mesmo que seja um indicativo de que a premissa adotada e testada para superação da barreira não deve ser mais seguida.",
    type: FieldTypeEnum.TEXTAREA,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
    counter: 4000,
    maxLength: 4000,
  },
  {
    key: "metodologia",
    name: "Metodologia/Contextualização das Etapas",
    ajuda:
      `Neste campo é necessário dissertar especificamente sobre cada atividade de pesquisa e desenvolvimento realizada no decorrer do ano-base, de maneira que fique evidenciada cada uma das etapas do processo de desenvolvimento do Projeto, relacionadas com o objetivo e a solução da barreira do Projeto. Descrever o que foi feito no Projeto, dividindo em macro etapas e suas atividades. Destacar o ano da execução. Ao fim de cada etapa informar o resultado da atividade, os participantes e o período de execução. Destacar qual parte da atividade foi executada por terceiros ou por ICT, se houver. * Novos conhecimentos adquiridos em razão do Projeto, * Estudo dos conceitos fundamentais técnico-científicos (mecânica, mecatrônica, outros) do equipamento, * Desenvolvimento do protótipo (matriz tecnológica e estrutural), * Estudo de normas, leis e parâmetros técnicos (segurança, meio ambiente etc.), * Testes e acompanhamento do modelo funcional. Obtenção de dados e elementos de avaliação (Comentar detalhadamente como e onde foram realizados os testes, os parâmetros utilizados, bem como a respeito das conclusões e resultados obtidos), * Se aplicável, informar se houve a fabricação de um ou mais lotes-piloto, e como se deu a sua fabricação, * Comentar sobre eventuais alterações do Projeto base, * Novos testes de validação (se aplicável): descrever os testes em detalhes, incluindo parâmetros utilizados, resultados satisfatórios e não satisfatórios, * Homologação final do Projeto (caso o Projeto ainda não tenha sido finalizado, é preciso comentar sobre as etapas que deverão ser realizadas no ano imediatamente subsequente), *Resultados obtidos no desenvolvimento das etapas *Detalhar pessoas envolvidas em cada atividade e atividades executadas por elas e por terceiros contratados.`,
    type: FieldTypeEnum.TEXTAREA,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
    counter: 4000,
    maxLength: 4000,
  },
  {
    key: "atividade_continua",
    name: "Atividade é continua (ciclo de vida maior que 1 ano?) (Escolha uma das opções)",
    type: FieldTypeEnum.RADIO,
    outline: true,
    labelBold: true,
    rules: [{ rule: "required" }],
    rel: {
      key: "id",
      name: "descricao",
      to: [
        { id: "Sim", descricao: "Sim" },
        { id: "Nao", descricao: "Não" },
      ],
    },
  },
  {
    key: "atividadeAnoBase",
    name:
      "Atividade de PD&I desenvolvida no ano-base",
    type: FieldTypeEnum.TEXTAREA,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
    // validaPreenchido: () => this.isLeiDoBem,
    counter: 4000,
    maxLength: 4000,
  },
  {
    key: "info_complementar",
    name: "Informação complementar",
    ajuda:
      "Informações complementares aos campos anteriores, bem como indicação facultativa de que a empresa enviará em anexo ao formulário com mais detalhamento das atividades de P&D realizadas ou que enviará via postal.",
    type: FieldTypeEnum.TEXTAREA,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
    counter: 4000,
    maxLength: 4000,
  },
  /* RESULTADO */
  {
    key: "resultado_economico",
    name: "Resultado Econômico",
    ajuda: "Informe os ganhos econômicos esperados pela empresa com a realização do projeto. São exemplos de informações que devem constar neste tópico: redução de custos em R$ XX; aumento de faturamento em R$ XX; aumento da lucratividade em R$ XX. ",
    type: FieldTypeEnum.TEXTAREA,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "max", params: { size: 250 } }],
    counter: 250,
    maxLength: 250,
  },
  {
    key: "resultado_inovacao",
    name: "Resultado de Inovação",
    ajuda: "Informe os ganhos da empresa na área de inovação. São exemplos de informações que devem constar neste tópico: ganhos de performance, produtividade, redução de custos, aumento de market-share etc.",
    type: FieldTypeEnum.TEXTAREA,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "max", params: { size: 250 } }],
    counter: 250,
    maxLength: 250,
  },
  {
    key: "recursos_humanos",
    name: "Recursos Humanos",
    ajuda:
      "Descrever a vinculação entre os colaboradores que participaram do projeto e as atividades realizadas, apresentando as competências que foram exigidas para execução do projeto. As competências referem-se as qualificações e tipos de profissionais envolvidos no projeto. Se aplicável, mencionar a participação de equipes  terceirizadas,  mencionando as atividades de P&D executadas.",
    type: FieldTypeEnum.TEXTAREA,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
    counter: 4000,
    maxLength: 4000,
  },
  {
    key: "vinculacao_materiais",
    name: "Vinculação entre os Materiais de Consumo e a Linha de Pesquisa",
    ajuda:
      // "Descrever a vinculação entre os materiais de consumo e a linha de pesquisa, apresentando as <strong>relações entre os materiais utilizados para execução do projeto e a linha de pesquisa</strong>. A relação material-linha de pesquisa deve esclarecer qual a finalidade do uso de determinado material de consumo para o desenvolvimento do projeto. Ex: Materiais utilizados no desenvolvimento de protótipos, materiais de consumo utilizados em testes etc.",
      "Descrever a vinculação entre os materiais de consumo e a linha de pesquisa, apresentando as relações entre os materiais utilizados para execução do projeto e a linha de pesquisa. A relação material-linha de pesquisa deve esclarecer qual a finalidade do uso de determinado material de consumo para o desenvolvimento do projeto. Ex: Materiais utilizados no desenvolvimento de protótipos, materiais de consumo utilizados em testes etc.",
    type: FieldTypeEnum.TEXTAREA,
    labelBold: true,
    rules: [{ rule: "required" }, { rule: "max", params: { size: 4000 } }],
    counter: 4000,
    maxLength: 4000,
  },
];
